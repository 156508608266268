<H2>Summary</H2>
<form
  name="form"
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
  novalidate
>

  <div class="form-group">

    <mat-form-field appearance="fill">
      <mat-label>Type of Summary</mat-label>
      <mat-select
        formControlName="typeSummary"
        required="required"
        (selectionChange)="columnLoad()"
      >
        <mat-option *ngFor="let item of fieldnames" [value]="item">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <ng-template [ngIf]="columnsdata && columnsdata.length != 0" >
    <mat-form-field appearance="fill">
      <mat-label>columnsdata</mat-label>
      <mat-select formControlName="columns" multiple required="required">
        <mat-select-trigger>
          {{columnsdata ? columnsdata[0] : ''}}
          <span *ngIf="columnsdata?.length > 1" class="example-additional-selection">
        ({{form.controls.columns.value.length + 'selected'}})
      </span>
        </mat-select-trigger>
        <mat-option *ngFor="let column of columnsdata" [value]="column">{{column}}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>
  <br>
  <ng-template [ngIf]=" form.controls.typeSummary.value == 'trips' || form.controls.typeSummary.value == 'day'" >
  <mat-form-field appearance="fill" class="example-form-field">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="rangePicker">
      <input matStartDate formControlName="start" placeholder="Start date">
      <input matEndDate formControlName="end" placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #rangePicker>
      <mat-date-range-picker-actions>
        <button mat-button matDateRangePickerCancel>Cancel</button>
        <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
  </mat-form-field>
  </ng-template>
  <ng-template [ngIf]=" form.controls.typeSummary.value == 'trips'" >
  <div class="form-group">
    <p>Trip Length in km</p>
    <ngx-slider [(value)]="slider.triplen.value" [(highValue)]="slider.triplen.highValue" [options]="slider.triplen.options"></ngx-slider>
  </div>
  <div class="form-group">
    <p>Fuel Consumption</p>
    <ngx-slider [(value)]="slider.consumption.value" [(highValue)]="slider.consumption.highValue" [options]="slider.consumption.options"></ngx-slider>
  </div>
  <div class="form-group">
    <p>Outside Temp</p>
    <ngx-slider [(value)]="slider.outsideTemp.value" [(highValue)]="slider.outsideTemp.highValue" [options]="slider.outsideTemp.options"></ngx-slider>
  </div>
  <div class="form-group">
    <p>Driving time in min</p>
    <ngx-slider [(value)]="slider.drivingTime.value" [(highValue)]="slider.drivingTime.highValue" [options]="slider.drivingTime.options"></ngx-slider>
  </div>
  </ng-template>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <button type="button" (click)="loadData()" class="btn btn-dark btn btn-primary btn-block">
        Update Chart
      </button>
    </div>
    <div class="col-sm-12 col-md-6">
      <button type="button" class="btn btn-dark btn btn-primary btn-block" (click)="downlaodDatawithQuery()">
        Generate CSV
      </button>
    </div>

    </div>

</form>

<ng-template [ngIf]="summaryData.length != 0 && lineChartLabels.length != 0">
<div style="display: block;">
  <canvas baseChart style="width: 100%; min-height: 50%; height: auto" width="100%"
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [plugins]="lineChartPlugins">
  </canvas>
</div>
</ng-template>
