<form
  name="form"
  [formGroup]="form"
  novalidate
>

  <div class="form-group">
<mat-form-field appearance="fill">
    <mat-label>Trip Nummer</mat-label>
    <mat-select
      formControlName="TripNummer"
      required="required"
      (selectionChange)="updateMap($event.value)"
    >
      <mat-option *ngFor="let item of triplist" [value]="item.trip_number">
        {{item.day}} {{item.time_Begins}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  </div>
</form>
<div id="map"></div>