import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { UserService } from '../_services/user.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css']
})
export class MapsComponent implements OnInit {
triplist: any;
  loadData(TripID){
    let url="https://yarix.test.lukas-server.de/api/summary/single/data/?output=json&tripID="+TripID
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.open( "GET", url, false ); // false for synchronous request
    xmlhttp.setRequestHeader("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJuYW1lIjoiTHVrYXMiLCJleHAiOjE3NDMwOTY2MDB9.ivxO3zZZdeL8OkZOYaQY43px_ciTTZuYDe5CHHvkEAk");
    xmlhttp.send( null );
    return JSON.parse(xmlhttp.responseText);
  }

  parsePopup(elm){
  var result=""
  Object.keys(elm).forEach(function(k){result+=k + ' - ' + elm[k]+"<br>";});
  return result;
  }

  updateMap(number: any){
    this.service.getSingle(number).subscribe( (resp) => {
      let data: any=resp['summary'];
      // @ts-ignore
      let middle=parseInt(data.length/2+1);
      this.map.off();
      this. map.remove();
      this. map = L.map('map').setView([data[middle].gps_lat, data[middle].gps_lon], 13);
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this.map);

      let i=0
      data.forEach((elm) => {
          if(i%10==0){
              if (elm.gps_lat!=0 && elm.gps_lon!=0)
              L.marker([elm.gps_lat, elm.gps_lon]).bindPopup(this.parsePopup(elm)).addTo(this.map);
          }
          i++
      });
      });

  }
  data: any[];
  map: L.map;
  form: any =  new FormGroup({
    TripNummer : new FormControl(),
  });

  constructor(private service: UserService) { }

  ngOnInit(): void {
    var trip=1
    const queryString = window.location.search;
    if(queryString==""){
        trip=1
    }else{
        trip=parseInt(queryString.substring(1))
    }

    this.service.getTriplist().subscribe((resp) => {
      this.triplist=resp['summary'];
    });
    this.map = L.map('map').setView([1,1], 13);
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this.map);
      this.updateMap(1);
  }



}
