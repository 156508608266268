<h1>Login</h1>
<form
  *ngIf="!isLoggedIn"
  name="form"
  (ngSubmit)="f.form.valid && onSubmit()"
  #f="ngForm"
  novalidate
>
  <div class="form-group">
    <label for="username">Username</label>
    <input
      type="text"
      class="form-control"
      name="username"
      minlength="3"
      [(ngModel)]="form.username"
      required
      id="username"
      #username="ngModel"
      autofocus
    />
    <div
      class="alert alert-danger"
      role="alert"
      *ngIf="username.errors && f.submitted"
    >
      Username is required!
    </div>
  </div>
  <div class="form-group">
    <label for="password">Password</label>
    <input
      type="password"
      class="form-control"
      name="password"
      [(ngModel)]="form.password"
      required
      minlength="3"
      id ="password"
      #password="ngModel"
      autofocus
    />
    <div
      class="alert alert-danger"
      role="alert"
      *ngIf="password.errors && f.submitted"
    >
      <div *ngIf="password.errors.required">Password is required</div>
      <div *ngIf="password.errors.minlength">
        Password must be at least 3 characters
      </div>
    </div>
  </div>
  <br><br>
  <div class="form-group">
    <button class="btn btn-primary btn-block">
      Login
    </button>
  </div>
  <div class="form-group">
    <div
      class="alert alert-danger"
      role="alert"
      *ngIf="f.submitted && isLoginFailed"
    >
      Username and password are incorrect or unknown. Please try again
    </div>
  </div>
</form>
<br>
<br>

